import {
  Header,
  Footer
} from '@components';
import { mapState } from 'vuex';

export default {
  components: {
    Header,
    Footer
  },
  computed: {
    ...mapState({
      campaignSetting: 'campaignSetting',
    }),
  },
  props: ['IpBlock'],
  created() {
    this.$store.dispatch('stopPageRedirectLoading');
  }
}
